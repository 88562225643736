import { useState, useEffect } from 'react';

export default function useThrottle(value, delay) {
    const [throttledValue, setThrottledValue] = useState(null);
    useEffect(() => {
        const timer = setInterval(() => setThrottledValue(value), delay);

        return () => clearInterval(timer);
    }, [throttledValue, delay]);

    return throttledValue;
}
